define("itarp-opportunity-designer/helpers/format-currency", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function formatCurrency([value]) {
    if (typeof value !== 'number') {
      return '$0';
    }

    const formattedValue = value.toString();
    return `$${formattedValue}`;
  }

  var _default = (0, _helper.helper)(formatCurrency);

  _exports.default = _default;
});