define("shared/transforms/asset-url", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const urlmatch = ['www', 'http'];
  const types = {
    file: 'download',
    content: 'show'
  };

  class AssetUrlTransform extends _transform.default {
    deserialize(serialized, options) {
      const resource = options.type;
      const type = types[resource] || types.content;
      if (!serialized || typeof serialized !== 'string') return null;
      if (this.isFullUrl(serialized)) return serialized;
      const name = serialized.split('/').pop();
      return `/api/expert/routing/storage/${type}/${name}`;
    }

    serialize(deserialized, options) {
      if (deserialized) {
        if (this.isFullUrl(deserialized)) return deserialized;
        const name = deserialized.split('/').pop();
        return name;
      }
    }

    isFullUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    }

  }

  _exports.default = AssetUrlTransform;
});