define("shared/serializers/profile", ["exports", "@ember-data/serializer/rest", "shared/serializers/data", "@ember/string"], function (_exports, _rest, _data, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ProfileSerializer extends _data.default.extend(_rest.EmbeddedRecordsMixin) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "attrs", {
        skills: {
          embedded: 'always'
        },
        tools: {
          embedded: 'always'
        },
        mentorshipExpertise: {
          embedded: 'always'
        },
        certifications: {
          embedded: 'always'
        },
        experiences: {
          embedded: 'always'
        },
        educations: {
          embedded: 'always'
        },
        practiceAreas: {
          embedded: 'always'
        },
        links: {
          embedded: 'always'
        },
        industries: {
          embedded: 'always'
        }
      });
    }

    serialize() {
      const json = super.serialize(...arguments);
      this.serializeRelationships(json.profiles);
      return json;
    }

    serializeRelationships(profile) {
      const embedded = key => !!this.attrs[key].embedded;

      const relationships = Object.keys(this.attrs).filter(embedded);
      relationships.forEach(name => {
        let modelName = relationship => Object.keys(relationship)[0];

        let attr = (0, _string.underscore)(name);

        if (Array.isArray(profile[attr])) {
          profile[attr] = profile[attr].map(relationship => relationship[modelName(relationship)]);
        } else {
          profile[attr] = profile[attr][modelName(profile[attr])];
        }
      });
    }

  }

  _exports.default = ProfileSerializer;
});