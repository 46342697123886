define("itarp-business-application/routes/my-favorites", ["exports", "@ember/routing/route", "@ember/service", "rsvp", "itarp-business-application/utils/opportunity"], function (_exports, _route, _service, _rsvp, _opportunity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MyFavoritesRoute = (_dec = (0, _service.inject)(), _dec2 = (0, _service.inject)('ad-token-service'), _dec3 = (0, _service.inject)('current-user'), (_class = class MyFavoritesRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        }
      });

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "adTokenService", _descriptor2, this);

      _initializerDefineProperty(this, "currentUser", _descriptor3, this);
    }

    buildRouteInfoMetadata() {
      return {
        title: 'My Favorites',
        sidebarCollapsed: true
      };
    }

    async model(params) {
      const id = this.adTokenService.tokenInformation.sub;
      let favorites = await this.store.query('user-favorite', {
        user_id: id,
        resource_type: 'opportunity',
        adapterOptions: {
          favorites: true
        },
        page: params.page ? params.page : _opportunity.DEFAULT_PAGE_NUMBER,
        per_page: _opportunity.RECORDS_PER_PAGE,
        order_by: ['updated_at', 'desc']
      });
      const selected = params.opportunityId ? favorites.findBy('id', params.opportunityId) : favorites.firstObject;
      let questions = [];

      if (selected) {
        const questionnaires = await this.store.query('questionnaire', {
          filter: {
            resource_id: selected.id,
            resource_type: 'opportunity'
          }
        });

        if (selected !== null && selected !== void 0 && selected.get('questions')) {
          for (const item of selected.get('questions').toArray()) {
            const question = questionnaires.findBy('id', item.get('questionnaire_id'));
            if (question) item.questionnaire = question;
          }
        }

        questions = selected.questions;
      }

      return (0, _rsvp.hash)({
        favorites,
        selectedProject: selected || null,
        questions,
        userId: id,
        user: this.currentUser.information
      });
    }

    setupController(controller, model) {
      var _model$favorites;

      const favorites = model.favorites;
      let meta = (_model$favorites = model.favorites) === null || _model$favorites === void 0 ? void 0 : _model$favorites.meta;
      super.setupController(...arguments);
      const favoriteItem = model.selectedProject ? model.favorites.findBy('id', model.selectedProject.id) : null;

      if (favoriteItem) {
        model.selectedProject.jobTitle = favoriteItem.jobTitle;
      }

      controller.set('favorites', favorites);
      controller.set('userId', model.userId);
      controller.set('noRecords', favorites.length === 0);
      controller.set('selectedProject', model.selectedProject);
      controller.set('user', model.user);
      controller.set('opportunityId', model.selectedProject ? model.selectedProject.id : null);

      if (meta) {
        const hasMultiplePages = meta.total > _opportunity.RECORDS_PER_PAGE;
        controller.set('pageMeta', meta);
        controller.set('total', meta.total);
        controller.set('hasMultiplePages', hasMultiplePages);
      }

      if (model.selectedProject) {
        this.controllerFor('my-favorites').isAppliedOpportunity(model.selectedProject.id);
      }
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('page', _opportunity.DEFAULT_PAGE_NUMBER);
        controller.set('opportunityId', null);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adTokenService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MyFavoritesRoute;
});