define("itarp-business-application/controllers/profile-preview", ["exports", "@ember/controller", "@ember/object", "@ember/service", "itarp-business-application/helpers/humanize"], function (_exports, _controller, _object, _service, _humanize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProfilePreviewController = (_class = class ProfilePreviewController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    previousRoute() {
      window.history.back();
    }

    get initials() {
      return [this.model.firstName, this.model.lastName].compact().map(name => name[0]).join('').toUpperCase();
    }

    get workArrangement() {
      let result = [];
      [this.profile.contractor, this.profile.partTimeAvailable, this.profile.fullTimeAvailable, this.profile.betweenPartFullTimeAvailable, this.profile.prefferedLocation, this.profile.remote, this.profile.localCommuteOnly, this.profile.willingToRelocate, this.profile.willingToTravel, this.profile.usWorkAuthorized, this.profile.requiresUsVisaSponsorship, this.profile.hasBusiness].forEach(item => {
        if (item !== null && item !== false && item !== undefined) {
          result.push(item);
        }
      });
      return result.length > 0 ? true : false;
    }

    get fullAddress() {
      return [this.profile.city, this.profile.state, this.profile.country].compact().without('').join(', ');
    }

    get pitchVideo() {
      const videoUrl = this.profile.pitchVideo;
      if (!videoUrl) return; // if url is from youtube return the embed url

      if (videoUrl.includes('youtube')) {
        return videoUrl.replace('watch?v=', 'embed/');
      }

      return videoUrl;
    }

    get workTypePreference() {
      const options = {
        Contractor: this.profile.contractor,
        'Part-time Permanent': this.profile.partTimeAvailable,
        'Full-time Permanent': this.profile.fullTimeAvailable,
        'I am flexible': this.profile.betweenPartFullTimeAvailable
      };
      return Object.keys(options).filter(key => options[key]).join(', ');
    }

    get genderEquityInclusion() {
      let attributes = [];

      if (this.profile.gender) {
        attributes.push((0, _humanize.default)(this.profile.gender));
      }

      if (this.profile.race) {
        attributes.push((0, _humanize.default)(this.profile.race));
      }

      if (this.profile.disability) {
        attributes.push('Disabled');
      }

      if (this.profile.veteran) {
        attributes.push('Veteran');
      }

      return attributes.join(', ');
    }

    stopPropagation(event) {
      event.stopPropagation();
    }

    imageLoadError(event) {
      event.target.src = '/itarp-shared-assets-addon/images/user_placeholder.png';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "previousRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "previousRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopPropagation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "stopPropagation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "imageLoadError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "imageLoadError"), _class.prototype)), _class);
  _exports.default = ProfilePreviewController;
});