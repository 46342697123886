define("itarp-template-designer/routes/templates", ["exports", "@ember/routing/route", "@ember/service", "rsvp", "itarp-template-designer/utils/constants/template", "itarp-template-designer/config/environment", "@glimmer/tracking", "@ember/object"], function (_exports, _route, _service, _rsvp, _template, _environment, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TemplatesRoute = (_class = class TemplatesRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "queryParams", {
        templateId: {
          refreshModel: false
        },
        categoryId: {
          refreshModel: false
        },
        page: {
          refreshModel: true
        },
        search_term: {
          refreshModel: true
        },
        category_type: {
          refreshModel: true
        }
      });

      _initializerDefineProperty(this, "resetCategoryType", _descriptor2, this);
    }

    async model({
      page,
      search_term,
      category_type
    } = {}) {
      return (0, _rsvp.hash)({
        categories: this.store.query('category', {
          include: 'templates,templates.templates',
          per_page: _template.RECORDS_PER_PAGE,
          page,
          search_term,
          category_type: category_type
        }, {
          reload: true
        }).then(data => {
          const {
            meta
          } = data;
          return {
            data,
            meta
          };
        }),
        search_term
      });
    }

    beforeModel() {
      this.resetCategoryType = true;
    }

    setupController(controller, model) {
      var _model$categories, _model$categories$met;

      super.setupController(controller, model);
      let hasMultiplePages = false;
      controller.set('categories', model.categories.data.toArray().sortBy('categoryOrder'));
      controller.set('sortableItems', this.setupSortableItems(model.categories.data));

      if ((_model$categories = model.categories) !== null && _model$categories !== void 0 && (_model$categories$met = _model$categories.meta) !== null && _model$categories$met !== void 0 && _model$categories$met.page) {
        var _model$categories$met2;

        controller.set('pageMeta', model.categories.meta.page);
        hasMultiplePages = ((_model$categories$met2 = model.categories.meta) === null || _model$categories$met2 === void 0 ? void 0 : _model$categories$met2.page.total) > _template.RECORDS_PER_PAGE;
      }

      if (model.search_term && !controller.searchInput) {
        controller.set('searchInput', model.search_term);
      }

      controller.set('hasMultiplePages', hasMultiplePages);

      if (controller.category_type) {
        controller.set('selectedCategory', controller.categoryTypes.find(categoryType => categoryType.value === controller.category_type));
      }
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('templateId', null);
        controller.set('categoryId', null);
        controller.set('search_term', null);
        controller.set('page', _template.DEFAULT_PAGE_NUMBER);
        controller.set('searchInput', null);
        controller.set('sortableItems', null);
        controller.set('draggedTemplate', null);
        controller.set('originItem', null);

        if (this.resetCategoryType) {
          controller.set('category_type', null);
        }
      }
    }

    setupSortableItems(categories) {
      return categories.toArray().sortBy('categoryOrder').map(category => {
        return {
          category,
          templates: category.templates.toArray().sortBy('templateOrder')
        };
      });
    }

    willTransition(transition) {
      var _transition$to;

      const engineName = _environment.default.modulePrefix;
      const templateRoutes = `${engineName}.templates`;
      const categoryRoutes = `${engineName}.categories`;
      const transitionName = (_transition$to = transition.to) === null || _transition$to === void 0 ? void 0 : _transition$to.name;

      if (transitionName !== null && transitionName !== void 0 && transitionName.includes(templateRoutes) || transitionName !== null && transitionName !== void 0 && transitionName.includes(categoryRoutes)) {
        this.resetCategoryType = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "resetCategoryType", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class);
  _exports.default = TemplatesRoute;
});