define("itarp-opportunity-designer/controllers/details/unsuccessful", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DetailsUnsuccessfulController = (_class = class DetailsUnsuccessfulController extends _controller.default {
    imageLoadError(event) {
      event.target.src = '/itarp-shared-assets-addon/images/user_placeholder.png';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "imageLoadError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "imageLoadError"), _class.prototype)), _class);
  _exports.default = DetailsUnsuccessfulController;
});