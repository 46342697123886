define("itarp-opportunity-designer/transforms/image-url", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ImageUrlTransform extends _transform.default {
    deserialize(serialized, options) {
      if (!serialized || typeof serialized !== 'string') return null;
      if (this.isFullUrl(serialized)) return serialized;
      const name = serialized.split('/').pop();
      return `/opportunity/storage/show/${name}`;
    }

    serialize(deserialized, options) {
      if (!deserialized || typeof deserialized !== 'string') return null;
      if (this.isFullUrl(deserialized)) return deserialized;
      const name = deserialized.split('/').pop();
      return name;
    }

    isFullUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    }

  }

  _exports.default = ImageUrlTransform;
});