define("itarp-components/components/table/candidates", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Ui::Table @scroll='true' as |table|>
    {{yield
      (hash
        candidatesBody=(component 'table/candidates/body' table=table)
        head=table.head
        body=table.body
      )
    }}
  </Ui::Table>
  */
  {
    "id": "0FKIMiqj",
    "block": "[[[8,[39,0],null,[[\"@scroll\"],[\"true\"]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"candidatesBody\",\"head\",\"body\"],[[50,\"table/candidates/body\",0,null,[[\"table\"],[[30,1]]]],[30,1,[\"head\"]],[30,1,[\"body\"]]]]]]],[1,\"\\n\"]],[1]]]]]],[\"table\",\"&default\"],false,[\"ui/table\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "itarp-components/components/table/candidates.hbs",
    "isStrictMode": false
  });

  let TableCandidateComponent = (_class = class TableCandidateComponent extends _component2.default {
    onImageLoadError(event) {
      event.target.src = '/itarp-shared-assets-addon/images/user_placeholder.png';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "onImageLoadError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onImageLoadError"), _class.prototype)), _class);
  _exports.default = TableCandidateComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TableCandidateComponent);
});