define("itarp-business-application/helpers/img-url", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.imgUrl = imgUrl;
  _exports.default = void 0;

  function isFullUrl(url) {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  }

  function imgUrl([imageUrl]) {
    if (!imageUrl || typeof imageUrl !== 'string') return;
    if (isFullUrl(imageUrl)) return imageUrl;
    const name = imageUrl.split('/').pop();
    return `/api/expert/routing/storage/show/${name}`;
  }

  var _default = (0, _helper.helper)(imgUrl);

  _exports.default = _default;
});