define("itarp-opportunity-ui/engine", ["exports", "ember-engines/engine", "ember-load-initializers", "itarp-opportunity-ui/resolver", "itarp-opportunity-ui/config/environment"], function (_exports, _engine, _emberLoadInitializers, _resolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    modulePrefix
  } = _environment.default;

  const ItarpOpportunityUiEngine = _engine.default.extend({
    modulePrefix,
    Resolver: _resolver.default,
    dependencies: {
      services: ['layout', 'workflows', 'toast', 'user-update-bus'],
      externalRoutes: ['expertise', 'autofill', 'basic-info', 'work-arrangement', 'dynamic-route']
    }
  });

  (0, _emberLoadInitializers.default)(ItarpOpportunityUiEngine, modulePrefix);
  var _default = ItarpOpportunityUiEngine;
  _exports.default = _default;
});